<template>
    <div>
      <AppMenu />
      <main class="lg:relative">
        <div
          class="w-full pt-16 pb-20 mx-auto text-center max-w-7xl lg:py-48 lg:text-left"
        >
          <div class="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <h1
              class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-3xl xl:text-5xl"
            >
              <span class="block xl:inline">Majo</span>
              <span class="block text-gray-900 xl:inline">Spa</span>
            </h1>
            <p
              class="max-w-md mx-auto mt-3 text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl"
            >
            The Majo Spa is a health and wellness facility that aims to provide a wide range of services to promote physical, mental, and emotional well-being. They offer cutting-edge services, including fitness plans, personal training, facials, injections, laser treatments, and more. The spa also hosts workshops, retreats, and events focused on stress management, mindfulness, and healthy living. Majo Spa is committed to making their services affordable and accessible to everyone in their community.      </p>
          </div>
        </div>
        <div
          class="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full"
        >
          <img
            class="absolute inset-0 object-cover w-full h-full"
            src="https://www.plasticsurgery.org/images/Blog/top-quality-med-spa.jpg?width=700&format=jpg"
            alt=""
          />
        </div>
      </main>

      <FooterSection />
    </div>
  </template>
  
  <script>
  import AppMenu from "../components/AppMenu";
  import FooterSection from "../components/FooterSection.vue";

  
  
  export default {
    name: "AboutSection",
    components: {
      AppMenu,

      FooterSection,
  
    },
  };
  </script>
  