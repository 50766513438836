<template>
    <div>
        <AboutSection />
    </div>
  </template>
  
  <script>
import AboutSection from "../components/AboutSection.vue"
  
  export default {
    name: "Home",
    components: {
      AboutSection

    },
    data() {
      return {

      };
    },
  };
  </script>
  